<template>
    <modal ref="modalBuscarPedido" titulo="Buscar pedidos" tamano="modal-lg" no-aceptar adicional="Buscar" @adicional="buscarPedidos">
        <div class="row mx-0">
            <div class="col-12 mb-4 text-center text-general f-15">
                Seleccione los criterios que necesite para la búsqueda de pedidos
            </div>
        </div>
        <div class="row mx-0 align-items-center justify-center mb-3">
            <div class="col-4 text-general f-17 f-600">
                Fecha de los pedidos
            </div>
            <div class="col-6">
                <el-date-picker
                v-model="rangoFechas"
                class="w-100 br-12"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="daterange"
                range-separator=""
                start-placeholder="Fecha Inicio"
                end-placeholder="Fecha Fin"
                />
            </div>
        </div>
        <div class="row mx-0 align-items-center mb-3 justify-center">
            <div class="col-4 text-general f-17 f-600">
                Franja horaria
            </div>
            <div class="col-6">
                <el-time-picker
                v-model="rangoHorario"
                class="w-100 br-12"
                value-format="HH"
                format="HH:mm"
                is-range
                arrow-control
                range-separator="a"
                start-placeholder="Hora Inicio"
                end-placeholder="Hora Fin"
                />
            </div>
        </div>
        <div class="row mx-0 mb-3 justify-center">
            <div class="col-4 text-general f-17 f-600">
                Distancia
            </div>
            <div class="col-6">
                <div class="row mx-0">
                    <div class="col pl-0 pr-2">
                        <el-input v-model="distanciaDesde" class="w-100" placeholder="Desde (m)" />
                    </div>
                    <div class="col pr-0 pl-2">
                        <el-input v-model="distanciaHasta" class="w-100" placeholder="Hasta (m)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mb-3 align-items-center justify-center">
            <div class="col-4 text-general f-17 f-600">
                Estados de pedidos
            </div>
            <div class="col-6">
                <el-select v-model="valueEstado" size="small" class="w-100" @change="addEstado">
                    <el-option
                    v-for="item in selectEstados"
                    :key="item.value"
                    :label="item.nombre"
                    :value="item.value"
                    :disabled="item.disabled"
                    />
                </el-select>
                <div class="row mx-0 mt-2">
                    <div v-for="(e,key) in estadosAgregados" :key="key" class="bg-general2 br-20 px-2 text-white m-1">
                        {{ e.nombre }} <i class="icon-cancel f-14 cr-pointer" @click="removerEstado(e.value)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mb-3 align-items-center justify-center">
            <div class="col-4 text-general f-17 f-600">
                CEDIS
            </div>
            <div class="col-6">
                <el-select v-model="valueCedis" size="small" placeholder="seleccionar" class="w-100" @change="addCedis">
                    <el-option
                    v-for="item in selectCedis"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    :disabled="item.disabled"
                    />
                </el-select>
                <div class="row mx-0 mt-2">
                    <div v-for="(c,key) in cedisAgregados" :key="key" class="bg-general2 br-20 px-2 text-white m-1">
                        {{ c.nombre }} <i class="icon-cancel f-14 cr-pointer" @click="removerCedis(c.id)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mb-3 align-items-center justify-center">
            <div class="col-4 text-general f-17 f-600">
                Vendedor
            </div>
            <div class="col-6">
                <el-select v-model="idLechero" :disabled="cedisAgregados.length === 0" clearable filterable size="small" class="w-100">
                    <el-option
                    v-for="item in lecheros"
                    :key="item.id_user"
                    :label="item.nombre"
                    :value="item.id_user"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Routing from '~/services/routing/rutas'
export default {
    data(){
        return{
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }],
            value: '',

            rangoFechas: '',
            rangoHorario: '',
            distanciaDesde: '',
            distanciaHasta: '',
            valueCedis:null,
            cedisAgregados:[],

            valueEstado:null,
            selectEstados:[
                {value:2, nombre:'Corfirmado'},
                {value:201, nombre:'Alistando'},
                {value:202, nombre:'Empacado'},
                {value:12, nombre:'Pedido con Cambios'},
            ],
            estadosAgregados:[],
            idLechero:null,
            lecheros:[]
        }
    },
    computed:{
        selectCedis(){
            return this.cedis_calculo.filter(o=>o.id != 0)
        },
        id_ruta(){
            return Number(this.$route.params.id_ruta)
        }
    },
    methods: {
        toggle(){
            this.$refs.modalBuscarPedido.toggle();
        },
        addCedis(id){
            let c = this.selectCedis.find(o=>o.id === id)
            c.disabled = true
            this.cedisAgregados.push(c)
            this.valueCedis = null
            this.getLecheros()
        },
        removerCedis(id){
            this.selectCedis.find(o=>o.id === id).disabled = false
            let c = this.selectCedis.find(o=>o.id === id)
            this.cedisAgregados.splice(this.cedisAgregados.indexOf(c), 1)
            this.getLecheros()
            this.idLechero = null

        },
        addEstado(value){
            let c = this.selectEstados.find(o=>o.value === value)
            c.disabled = true
            this.estadosAgregados.push(c)
            this.valueEstado = null
        },
        removerEstado(value){
            this.selectEstados.find(o=>o.value === value).disabled = false
            let c = this.selectEstados.find(o=>o.value === value)
            this.estadosAgregados.splice(this.estadosAgregados.indexOf(c), 1)
        },
        async getLecheros(){
            try {
                let cedis = []
                for (const c of this.cedisAgregados){
                    cedis.push(c.id)
                }
                if(cedis.length === 0){
                    this.lecheros = []
                    return
                }
                const params = {
                    cedis:cedis
                }
                const {data} = await Routing.getLecheros(params)
                this.lecheros = data.lecheros

            } catch (e){
                this.error_catch(e)
            }
        },
        async buscarPedidos(){
            try {

                let estados = []
                let criterioEstados = []
                for (const e of this.estadosAgregados){
                    estados.push(e.value)
                    criterioEstados.push(e.nombre)
                }
                let cedis = []
                let criterioCedis = []
                for (const c of this.cedisAgregados){
                    cedis.push(c.id)
                    criterioCedis.push(c.nombre)
                }

                const datos = {
                    fechas: this.rangoFechas,
                    horario: this.rangoHorario,
                    distancia_minima: this.distanciaDesde,
                    distancia_maxima: this.distanciaHasta,
                    estados: criterioEstados,
                    cedis: criterioCedis,
                    lechero: this.idLechero ? this.lecheros.find(o=>o.id_user == this.idLechero).nombre : 'No Aplica'
                }

                const params = {
                    fechas:this.rangoFechas,
                    horario:this.rangoHorario,
                    distancia_minima: this.distanciaDesde,
                    distancia_maxima: this.distanciaHasta,
                    estados,
                    cedis,
                    lechero:this.idLechero
                }

                this.$emit('buscar', {datos,params})

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
